@import "../settings/_index.scss";
.phone {
    display: none;
    position: relative;
    box-sizing: border-box;
    margin-top: 30px;
    padding: 0 10px;
    border-top-left-radius: 43px;
    border-top-right-radius: 43px;
    box-shadow: 0px 0px 12px 0px rgba(51,50,51,0.5);
    margin-left: 50px;
    margin-right: 50px;
    background-color: darken(#fff, 2%);
    &_dark {
        background-color: #000;
    }
    &-header {
        &__dynamic {
            position: relative;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            &::before {
                position: relative;
                display: block;
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: rgba(76,76,76,1);
                background: -moz-radial-gradient(center, ellipse cover, rgba(76,76,76,1) 0%, rgba(17,17,17,1) 60%, rgba(43,43,43,1) 76%, rgba(28,28,28,1) 91%, rgba(19,19,19,1) 100%);
                background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(60%, rgba(17,17,17,1)), color-stop(76%, rgba(43,43,43,1)), color-stop(91%, rgba(28,28,28,1)), color-stop(100%, rgba(19,19,19,1)));
                background: -webkit-radial-gradient(center, ellipse cover, rgba(76,76,76,1) 0%, rgba(17,17,17,1) 60%, rgba(43,43,43,1) 76%, rgba(28,28,28,1) 91%, rgba(19,19,19,1) 100%);
                background: -o-radial-gradient(center, ellipse cover, rgba(76,76,76,1) 0%, rgba(17,17,17,1) 60%, rgba(43,43,43,1) 76%, rgba(28,28,28,1) 91%, rgba(19,19,19,1) 100%);
                background: -ms-radial-gradient(center, ellipse cover, rgba(76,76,76,1) 0%, rgba(17,17,17,1) 60%, rgba(43,43,43,1) 76%, rgba(28,28,28,1) 91%, rgba(19,19,19,1) 100%);
            }

            &::after {
                position: relative;
                display: block;
                content: "";
                height: 6px;
                width: 70px;
                margin-left: 10px;
                border-radius: 30px;
                background: rgba(255,255,255,1);
                background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 0%, rgba(199,199,199,1) 80%, rgba(133,133,133,1) 88%, rgba(255,255,255,1) 100%);
                background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(255,255,255,1)), color-stop(80%, rgba(199,199,199,1)), color-stop(88%, rgba(133,133,133,1)), color-stop(100%, rgba(255,255,255,1)));
                background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 0%, rgba(199,199,199,1) 80%, rgba(133,133,133,1) 88%, rgba(255,255,255,1) 100%);
                background: -o-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 0%, rgba(199,199,199,1) 80%, rgba(133,133,133,1) 88%, rgba(255,255,255,1) 100%);
                background: -ms-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 0%, rgba(199,199,199,1) 80%, rgba(133,133,133,1) 88%, rgba(255,255,255,1) 100%);
            }
        }
    }
    @media screen and (min-width: $desktop-view) {
        & {
            display: block;
        }
    }
}