$z-card: (
        normal: 1,
        2: 20,
        3: 30,
        4: 40,
        5: 50,
        6: 60,
        7: 70,
        8: 80,
        modal: 90,
        super-modal: 100,
);
