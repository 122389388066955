@import "./settings/_index.scss";
@import "./utils/initialize";


/**
    @modules - initialize stylesheet
    @description - normalize stylesheet and set base styles
 */
@include initialize();
@import "./modules/link";

/**
    @modules - import pages
    @description - import all pages styles
 */
@import "./modules/filter";
@import "./modules/result-card";
@import "./modules/table";

@import "./pages/index";
@import "./pages/how-it-work";
@import "./pages/contact";
@import "./pages/details";
@import "./pages/search-result";
@import "./pages/_not-found.scss";
@import "./pages/_verify-email.scss";
