@import "../settings/index";
@import "../settings/typographic";
@import "../utils/grid";
@import "../utils/media";

.cards {
  @include grid-container();
  padding: 0!important;
  &__list {
    @include grid-row();
    & {
      margin: 0;
    }
  }
  &__item {
    @include grid-col($xs: 6, $md: 4, $lg: 4, $xl: 4);
    padding-left: 0!important;
    padding-right: 0!important;
    & {
      margin-bottom: rem(20px);
    }
  }
  &-controller-container {
    & {
      display: flex;
      position: relative;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: rem(18px);
    }
    &_mobile {
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (min-width: $desktop-view) {
    .cards-controller-container {
      display: flex;
      margin-bottom: rem(77px);
      &_mobile {
        display: none;
      }
    }
    &-result {
      margin-bottom: 80px;
    }
  }
}

.card {
  box-sizing: border-box;
  list-style: none;
  display: block;
  position: relative;
  padding-left: rem(8px);
  padding-right: rem(8px);
  &.sold {
    &:before {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("../../../../www/images/icons/sold/sold.png");
      background-position: 10% 10%;
      background-size: 60%;
      background-repeat: no-repeat;
      z-index: 2;
    }
  }

  &__link {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
  }
  &__media {
    box-sizing: border-box;
    position: relative;
    margin-bottom: rem(4px);
    line-height: 28px;
    display: block;
    width: 100%;
    &-car-pic {
      width: 100%;
      min-width: 80px;
      height: 190px;
      object-fit: cover;
      @media screen and (max-width: 600px) {
        height: 140px;
      }
    }
  }
  &-particular {
    & {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 8px;
      padding-right: rem(4px);
      padding-left: rem(4px);
      background-color: #f4e200;
    }
    &__content {
      font-size: rem(map-get($font-size, xss));
      font-weight: 400;
      line-height: 20px;
      background-image: url('../../../../www/images/icons/diamound/shape.svg');
      background-position: center right;
      background-size: rem(16px);
      background-repeat: no-repeat;
    }
    @include media-desktop() {
      &__content {
        font-weight: 500;
        line-height: 28px;
      }
    }
  }
  &__body {
    & {
      position: relative;
      box-sizing: border-box;
      line-height: rem(16px);
      text-align: left;
    }
    &-text {
      & {
        font-size: rem(10px);
        font-weight: 400;
        color: #5d5d5d;
      }
      &_lead {
        font-size: rem(12px);
        font-weight: 800;
        text-align: left;
        color: #622651;
      }
    }
    &-article {
      font-size: rem(12px);
      font-weight: 600;
      color: #313131;
      margin: 0;
      text-align: left;
    }
  }
  &__article_freedom {
    text-align: center;
    font-size: rem(16px);
    line-height: rem(18px);
    font-weight: 400;
    color: #000;
  }

  @include media-desktop() {
    &__body {
      line-height: 1.3;
      &-text {
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #5d5d5d;
        &_lead {
          font-size: 20px;
          font-weight: 800;
          text-align: left;
          color: #622651;
        }
      }
      &-article {
        font-size: rem(16px);
        font-weight: 600;
      }
    }
  }
  &-description {
    position: relative;
    padding: 20px;
    margin-bottom: 16px;
  }
}