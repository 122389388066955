@import "../settings/index";
@import "../settings/typographic";
@import "../utils/grid";

.banner {
  ._Base-Banner {
    position: relative;
    box-sizing: border-box;
    display: block;
  }
  @mixin use-background-image($image-url-large: null, $image-url-xs: null) {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @if $image-url-xs != null {
      background-image: url($image-url-xs);
    } @else {
      background-image: url($image-url-large);
    }
    @if $image-url-large != null {
      @media screen and (min-width: $desktop-view){
        background-image: url($image-url-large);
      }
    }
  }
  @extend ._Base-Banner;
  @include use-background-image(
          $image-url-large: '../../../../www/images/banner/banner/drawable-xhdpi/banner.png',
          $image-url-xs: '../../../../www/images/banner/banner/drawable-mdpi/banner.png');

  & {
    margin-bottom: rem(16px); /*20px*/
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 190px;
  }

  &-footer {
    @extend ._Base-Banner;
    @include use-background-image('../../../../www/images/banner/footer.png');
    & {
      display: none;
      @media screen and (min-width: 760px) {
        display: block;
      }
    }
  }
  &-wrapper {
    & {
      position: relative;
      box-sizing: border-box;
      text-align: center;
      width: 100%;
    }
    &-footer {
      box-sizing: border-box;
      padding-top: rem(30px); /*30px*/
      padding-bottom: rem(50px); /*50px*/
    }
  }
  &-content {
    & {
      position: relative;
      box-sizing: border-box;
      margin-left: auto;
      margin-right: auto;
      max-width: 320px;
    }
    &__item {
      margin-bottom: rem(10px); /*17px*/
      display: flex;
      justify-content: center;
      &:last-child {
        text-align: center;
        .list-horizontal:last-child {
          display: none;
        }
      }
    }
    &__title {
      text-align: center;
      line-height: 30px;
      font-weight: 200;
      font-size: map_get($font-size, xl);
      color: $color-font_light;
    }
    &__text {
      display: none;
      font-weight: 400;
    }
  }

  &-footer__title {
    font-size: rem(20px); /*20px*/
  }
  &-footer {
    .shops-list {
      justify-content: center;
    }
  }

  @media screen and (min-width: $desktop-view) {
    & {
      border-bottom: 8px solid $color-primary;
      min-height: 460px;
      justify-content: center;
      margin-bottom: rem(38px);
    }
    &-content {
      &_left-space {
        padding-left: 15%;
      }
      & {
        padding-top: $header-heigth;
        max-width: 65%;
      }
      &__title {
        padding-top: 0;
        font-size: rem(28px);
        text-align: left;
        line-height: 1.3;
        font-weight: 400;
        letter-spacing: -0.3px;
      }
      &__text {
        display: block;
        color: $color-font_light;
        text-align: left;
        font-size: rem(18px);
        line-height: 1.3;
        font-weight: 400;
      }
      &__item:last-child {
        text-align: left;
        .list-horizontal:last-child {
          display: inline-block;
        }
      }
    }
    &-footer-content__item {
      justify-content: center!important;
    }
    &-footer__title {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: -0.2px;
      text-align: center;
    }
  }
}