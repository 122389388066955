@import "../settings/index";
@import "../utils/grid";

.header {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: map_get($z-card, 2);
  background-color: rgba(0,0,0, 0.8);
  border-top: 5px solid $color-second;
  &:after, &:before {
    content: "";
    display: block;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    min-height: 60px;
  }

  &_theme-nested {
    position: relative;
    background-color: #fff;
    border-top: 4px solid $color-primary;
  }

  @media screen and (min-width: $desktop-view) {
    & {
      min-height: 90px;
      border-top: 8px solid $color-second;
      &_theme-nested {
        border-top: 8px solid $color-primary;
        border-bottom: 4px solid $color-second;
      }
    }
    &__container {
      height: 90px;
      align-items: center;
    }
  }
}