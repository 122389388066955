@import "../settings/index";
@import "../settings/typographic";
@import "../utils/media";
.result-card {
  &:after {
    display: block;
    content: "";
    clear: both;
  }

  &-grid-system {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: rem(30px);
    &__child {
      width: 100 / 2 + 0%;
      padding-left: 8px;
      padding-right: 8px;
      margin-bottom: 10px;
    }
  }
  display: flex;
  flex-direction: column;
  &__media {
    flex-grow: 1;
    order: 0;
    margin-bottom: 4px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__sail-details {
    order: 1;
    .text, span, p {
      display: inline;
      font-size: 14px;
    }
  }

  &__car-details {
    order: 2;
  }

  &__title {
    display: block;
    font-size: rem($normal-text);
    font-weight: 600;
    color: $color-font_dark;
    margin-top: 0;
    margin-bottom: 10px;
  }

  @include media-desktop() {
    $border-color: #e6e6e6;
    $border-color_active: $color-second;
    $indent: 8px;

    & {
      transition: all 300ms;
      min-height: 180px;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 14px;
      padding-bottom: 14px;
      border-top: 1px solid $border-color;
      &.active {
        border-top: 3px solid $border-color_active;
        border-bottom: 3px solid $border-color_active;
      }
    }

    &__link {
      &:hover {
        border-top: 2px solid $color-second;
        border-bottom: 2px solid $color-second;
      }
    }
    &-grid-system {
      display: flex;
      flex-wrap: wrap;
      margin-left: 0;
      margin-right: 0;
      padding-left: 20px;
      &__child {
        width: 100%;
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__media {
      max-width: 220px;
      min-width: 180px;
      margin-bottom: 0;
      background: #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;

      &.sold {
        &:before {
          display: block;
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: url("../../../../www/images/icons/sold/sold.png");
          background-position: 10% 10%;
          background-size: 60%;
          background-repeat: no-repeat;
          z-index: 2;
        }
      }
    }

    &__media, &__sail-details, &__car-details {
      margin-left: $indent;
      margin-right: $indent;
    }
    &__media {
      margin-left: 0;
    }
    &__sail-details {
      margin-right: 0;
    }

    &__title {
      font-size: rem(18px);
      font-weight: 500;
      color: #383838;
    }

    &__sail-details, &__car-details {
      flex-grow: 2;
      .text {
        font-weight: 400;
        line-height: 16px;
        font-size: 12px;
      }
      &:last-child {
        text-align: right;
      }
    }

    &__car-details {
      order: 1;
    }

    &__sail-details {
      order: 2;
      p {
        display: block;
        font-size: 20px;
        span {

          font-size: 20px;
        }
      }
      p ~ span {
        display: none;
      }
    }
    &__list {
      li {
        list-style: none;
      }
    }
  }
  &_stub {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    &:hover {
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      cursor: default;
    }
  }
  &__car-details_center-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
