@import "../settings/index";
.footer {
  display: none;
  @media screen and (min-width: $desktop-view) {
    & {
      display: block;
      position: relative;
      box-sizing: border-box;
      background-color: #1e1e1e;
      flex: 0 0 auto;
    }
    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      padding-left: rem(16px);
      padding-right: rem(16px);
    }
    &__item {
      box-sizing: border-box;
      position: relative;
      color: #fff;
      font-size: rem(12px);
      font-weight: 400;
    }
  }
}