@import "../settings/index";
@import "../utils/index";

.link {
  @include stylesheet-link();
}

.link {
  &-text {
    @include stylesheet-link($size: 12px) {
      &:hover {
        color: $color-font_dark;
      }
    };
  }

  &_light-color {
    @include stylesheet-link($color-font_light);
  }

  &_blue-color {
    @include stylesheet-link();
    color: $color-there!important;
    font-size: rem(12px)!important;
    &:hover {
      color: darken($color-there, 10%);
    }
    &:visited {
      color: $color-there;
    }
    &-underline {
      @extend .link_blue-color;
      text-decoration: underline;
    }
  }
  &__full-parent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-decoration: none;
    z-index: map-get($z-card, 2);
  }
}
