@import "../settings/index";
.car-info {
  box-sizing: border-box;
  display: block;
  position: relative;
  &-list {
    background-color: $color-background_second;
    box-sizing: border-box;
    position: relative;
    padding: rem($indent);
    margin: 0;
    font-size: rem(14px);
    margin-bottom: rem(30px);
    &__item {
      list-style: none;
      column-count: 2;
      word-break: break-all;
      line-height: 1.5;
      &-article, &-text {
        display: block;
      }
      &-article {
        font-weight: 500;
      }
    }
  }
}