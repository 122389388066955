@import "../settings/index";

.table {
    & {
        display: block;
        position: relative;
        width: 100%;
        padding-top: rem($indent);
        margin-bottom: rem($indent);
    }

    &-body {
        display: block;
    }

    &__line {
        display: flex;
        justify-content: space-between;
        margin-bottom: rem($indent);
    }

    &__cell {
        box-sizing: border-box;
        flex-shrink: 1;
        flex-grow: 1;
        display: block;
        font-size: 16px;
        position: relative;
        padding: 0 10px;
        text-align: left;
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        width: 100%;

        &:first-child { padding-left: 0; }
        &:last-child { padding-right: 0; }

        &.article {
            font-weight: 500;
            box-sizing: border-box;
            flex-grow: 2;
            flex-shrink: 2;
        }
        &_w25 { width: 25%; }
    }
}