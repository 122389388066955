@import "../settings/index";
@import "../settings/typographic";
@import "../utils/media";

.navigation {
  & {
    position: relative;
    box-sizing: border-box;
  }
  &-expand {
    position: relative;
    top: 10px;
    width: 20px;
    height: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 25px;
    cursor: pointer;
    z-index: map-get($z-card, modal);
    &__element {
      display: block;
      height: 3px;
      width: 20px;
      border-radius: 6px;
      background-color: #fff;
      position: relative;
      transition: all 300ms ease-out;
      &_theme-dark {
        background-color: #000;
      }
    }
  }

  &-container {
    transition: all 200ms ease-out;
    position: fixed;
    top: 0;
    bottom: 0;
    right: -280px;
    margin-top: auto;
    margin-bottom: auto;
    box-sizing: border-box;
    width: 280px;
    padding-top: 46px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #1f1f1f;
    &_theme-nested {
      background-color: #fff;
    }
  }
  &__item {
    & {
      list-style: none;
    }
    &-link {
      color: #fff;
      font-size: rem(15px);
      font-weight: 400;
      transition: all 200ms ease;
      display: block;
      padding-left: 40px;
      line-height: 35px;
      &:not(&_disabled) {
        &:hover {
          color: darken(#fff, 20%);
        }
      }
      
      &_disabled {
        cursor: default;
      }

      &_theme-nested {
        color: #000;
        &:not(&_disabled) {
          &:hover {
            color: darken(#000, 80%);
          }
        }
      }
      &_small {
        font-size: rem(map_get($font-size, xss));
      }
    }
    &:after {
      transition: all 200ms ease-out;
      content: "";
      position: relative;
      display: block;
      height: 2px;
      width: 100%;
      background-color: #fff;
      opacity: 0;
    }
    &:not(&_disabled) {
      &:hover {
        &:after {
          opacity: 0.5;
        }
      }
    }
    &_theme-nested {
      &:after {
        transition: all 200ms ease-out;
        content: "";
        position: relative;
        top: 2px;
        display: block;
        height: 2px;
        width: 100%;
        background-color: $color-primary;
        opacity: 0;
      }
      &:hover {
        &:after {
          opacity: 0.5;
        }
      }
    }
    &-icon {
      $light-url: '../../../../www/images/icons/navigate/light/';
      $dark-url: '../../../../www/images/icons/navigate/dark/';
      position: relative;
      background-repeat: no-repeat;
      background-position-y: center;
      &_home {
        background-image: url($light-url + 'home.svg');
        &-dark {
          background-image: url($dark-url + 'home.svg');
        }
      }
      &_search {
        background-image: url($light-url + 'search.svg');
        &-dark {
          background-image: url($dark-url + 'search.svg');
        }
      }
      &_how-it-work {
        background-image: url($light-url + 'howItWork.svg');
        &-dark {
          background-image: url($dark-url + 'howItWork.svg');
        }
      }
      &_contact {
        background-image: url($light-url + 'email.svg');
        &-dark {
          background-image: url($dark-url + 'email.svg');
        }
      }
      &_service {
        background-image: url($light-url + 'services.svg');
        &-dark {
          background-image: url($dark-url + 'services.svg');
        }
      }
    }
  }


  @include media-desktop() {
    &-expand {
      display: none;
    }
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: auto;
      right: 0;
      padding: 0;
      background: inherit;
      box-shadow: none;
    }
    &__item {
      display: inline-block;
      margin-left: 15px;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
      &_active {
        &:after {
          opacity: 1;
        }
      }
      &-link {
        padding: 0;
      }
      &-icon {
        &_home, &_how-it-work, &_contact, &_service {
          &, &-dark {
            background: none;
          }
        }
        &_search {
          &, &-dark {
            padding-left: 25px;
          }
        }
      }
    }
  }

  /**
    @description active slide menu
   */
  &_active {
    .navigation-expand {
      top: 7px;
    }
    .navigation-expand__element {
      transform-origin: center;
      display: none;
      &:first-child {
        display: block;
        transform: rotate(45deg);
      }
      &:last-child {
        position: absolute;
        display: block;
        transform: rotate(-45deg);
      }
      &:nth-child(2) {
        display: none;
      }
    }

    .navigation-container {
      box-shadow: -2px 0px 26px 0px rgba(0,0,0,0.56);
      right: 0;
    }
  }
}
.navigate__item_glue-bottom {
  position: absolute;
  bottom: rem($normal-text);
  box-sizing: border-box;
  list-style: none;
  @include media-desktop() {
    display: none;
  }
}