@import "../settings/index";
.icon {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  display: block;
  box-sizing: border-box;

  &-success {
    background-image: url('../../../../www/images/icons/success-icon.svg');
    background-position: left 12px;
    background-size: auto;
    background-repeat: no-repeat;
    padding-left: rem(32px);
  }

  &-shops {
    @extend .icon;
    width: 100%;
    min-width: 98px;
    height: 30px;
    &_app-story {
      background-image: url('../../../../www/images/icons/app_store/drawable-xhdpi/appstore.png');
    }
    &_play-story {
      background-image: url('../../../../www/images/icons/goole_play/drawable-hdpi/playstore.png');
      &-comming-soon {
        background-image: url('../../../../www/images/icons/google_play__comming_soon/drawable-hdpi/playstore_copy.png');
      }
    }
  }
  &-social {
    @extend .icon;
    min-width: 40px;
    width: 100%;
    height: 40px;
    &_facebook {
      background-image: url('../../../../www/images/icons/facebook_copy.svg');
    }
    &_instagram {
      background-image: url('../../../../www/images/icons/instagram/drawable-hdpi/instagram_copy.png');
    }
  }

  &-how-it-work {
    @mixin _BaseIcon($url: null) {
      @if ($url != null) {
        background-image: url($url);
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto;
        height: 80px;
        padding-top: rem(20px);
        margin-bottom: rem(20px);
      }
    }
    $url: '../../../../www/images/icons/how_it_work/';
    &_taxi {
      @include _BaseIcon($url + 'taxi.svg');
    }
    &_money {
      @include _BaseIcon($url + 'coin.svg');
    }
    &_phone {
      @include _BaseIcon($url + 'smartphone.svg');
    }
    &_clock {
      @include _BaseIcon($url + 'clock.svg');
    }
    &_chat {
      @include _BaseIcon($url + 'chat.svg');
    }
    &_check {
      @include _BaseIcon($url + 'check.svg');
    }
  }

  @media screen and (min-width: 760px) {
    &-shops {
      background-size: 130px 40px;
      height: 40px;
      width: 130px;
    }
  }
}