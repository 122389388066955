@import "../settings/index";
.payment-details {
  $font-size: 14px;

  &__text {
    font-size: rem(14px);
    line-height: 1.29;
    letter-spacing: -0.2px;
    text-align: left;
    color: #000000;
    font-weight: 400;
    margin: 0;
  }

  &__title {
    @extend h1;

    font-weight: 600;
  }

  position: relative;
  box-sizing: border-box;
  &__section {
    margin-bottom: rem($indent);
  }
  &__header {
    position: relative;
    box-sizing: border-box;
    margin-bottom: rem(10px);
    display: flex;
    align-items: center;
    line-height: 55px;
    font-size: rem($font-size);
    &-cost {
      display: block;
      font-size: rem(24px);
      font-weight: 600;
      color: $color-primary;
      padding-right: rem(5px);
    }
  }
  &-list {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: rem($font-size);
    font-weight: 400;
    color: #000;
    line-height: 1.5;
    margin-bottom: rem($indent);
    &__item {
      list-style: none;
      display: block;
      column-count: 2;
      &-article {
        font-weight: 500;
      }
    }
  }
  &-shops {
    display: none;
    @include media-desktop() {
      display: flex;
      justify-content: space-between;
    }
  }

  @media screen and (min-width: $desktop-view) {
    & {
      border: 4px solid $color-primary;
      padding: rem($indent);
    }
  }
}
