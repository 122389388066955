@import "../settings/index";
@import "../utils/grid";
.section {
  box-sizing: border-box;
  position: relative;
  border-top: 4px solid $color-second;
  &_last {
    border-top-color: $color-primary;
  }
  &-header {
    box-sizing: border-box;
    margin-top: rem(16px);
    margin-bottom: rem(38px);
    &__title {
      font-size: rem(28px);
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      text-align: left;
      color: #222222;
    }
    h2 {
      margin: 0;
    }
  }
  &-body {
    & {
      box-sizing: border-box;
      position: relative;
      padding-bottom: rem(60px);
      padding-left: 10%;
      padding-right: 10%;
      padding-top: rem(38px);
    }

  }
  &_grey-bg {
    background-color: #f5f5f5;
  }
}