@import "../modules/header";
@import "../modules/logo";
@import "../modules/navigation";
@import "../modules/banner";
@import "../modules/fruits";
@import "../modules/cards";
@import "../modules/buttons";
@import "../modules/paginate";
@import "../modules/list";
@import "../modules/icons";
@import "../modules/footer";