@import "../settings/typographic";
@import "../utils/grid";
.fruits {
  margin-bottom: rem(20px);
  &-grid-container {
    @include grid-container();
  }
  &-list {
    @include grid-row();
    position: relative;
    box-sizing: border-box;
    &__item {
      @include grid-col($xs: 4, $md: 4, $lg: 4, $xl: 4);
      list-style: none;
      display: block;
      width: 100%;
      line-height: rem(22px);
      text-align: center;
    }
    &__fruit {
      box-sizing: border-box;
      padding-left: rem(8px);
      padding-right: rem(8px);
      text-align: center;
      &-figure {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        max-width: 40px;
        height: 25px;
        &_hands {
          max-width: 30px;
        }
        svg {
          width: 100%;
          height: auto;
        }
      }
      &-body {
        position: relative;
        box-sizing: border-box;
        text-align: center;
        line-height: 18px;
      }
      &-title {
        font-size: rem(16px);
        color: #000;
      }
      &-text {
        font-size: rem(14px);
        color: #000;
      }
    }
  }
  @media screen and (min-width: 768px) {
    &-list__fruit {
      &-figure {
        max-width: 60px;
        height: 60px;
        &_hands {
          max-width: 50px;
        }
      }
      &-body {
        line-height: 32px;
      }
    }
  }
}