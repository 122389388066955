.not-found {
    &-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &-title {
        margin-top: 24px;
        margin-bottom: 50px;
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        img {
            max-width: 220px;
            max-height: 220px;
            width: 100%;
            height: auto;
        }
    }

    &__description {
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        line-height: 2;
    }
}

