@mixin stylesheet-link($size: $normal-text, $color: $color-font_dark, $font-weight: 400) {

  & , &:visited {
    text-decoration: none;
    font-size: $size;
    font-weight: $font-weight;
    color: $color;
    transition: all 300ms ease;
  }

  &:hover {
    color: lighten($color, 20%);
  }

  @content;
}
