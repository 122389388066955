@import "../utils/grid";
@import "../modules/breadcrump";
@import "../modules/photo-gallery";
@import "../modules/car-info";
@import "../modules/details-info";
@import "../modules/payment-details";
@import "../modules/accordion";
@import "../modules/gallery";

.details {
  &-title {
    font-size: rem(map_get($font-size, xl));
    font-weight: 400;
  }
  &-header {
    position: relative;
    box-sizing: border-box;
    padding-top: rem(15px);
    padding-bottom: rem(15px);
  }
  &-navigate {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    &__item {
      display: inline-block;
      list-style: none;
      &-link {
        color: #000;
        font-size: rem($normal-text);
        font-weight: 400;
        &_bold {
          font-size: rem(26px);
        }
        &:hover {
          color: lighten(#000, 30%);
        }
      }
    }
  }
  &-section {
    position: relative;
    box-sizing: border-box;
    display: block;
    &__title {
      margin: 0;
      padding: 0;
      font-size: rem(28px);
      font-weight: 400;
      color: #383838;
      margin-bottom: rem($indent);
    }
    &__body {
      position: relative;
      box-sizing: border-box;
      margin-bottom: rem(60px);
      iframe {
        position: relative;
        box-sizing: border-box;
        width: 100%;
      }
    }
  }
  &-layout {
    @include grid-container();
    &__wrapper {
      @include grid-row();
      margin-left: - rem($indent!important);
      margin-right: - rem($indent!important);
    }
    &__left-side {
      @include grid-col($xs: 12, $md: 7, $lg: 7, $xl: 7);
    }
    &__right-side {
      @include grid-col($xs: 12, $md: 5, $lg: 5, $xl: 5);
    }

    @include media-desktop() {
      &__left-side {
        padding-right: 25px!important;
      }
      &__right-side {
        padding-left: 25px!important;
      }
    }
  }

  &-gallery {
    position: relative;
    box-sizing: border-box;
    &__container {
      @extend ._GalleryContainerClass;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &-slide {
      @extend ._GalleryItemSlideClass;
      display: inline-block;
      width: 80px;
      height: 60px;
      margin-left: 5px;
      margin-right: 5px;
      cursor: pointer;
      order: 1;
      margin-bottom: 10px;
      transition: all 800ms ease;
      img {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &_active {
        width: 100%;
        height: 300px;
        flex-grow: 2;
        order: 0;
      }
    }
  }
  @include media-desktop() {
    &-navigate {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  &-car-location {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 550px;
    min-width: 200px;
  }
}
