@import "../settings/index";
._GalleryClass {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

._GalleryContainerClass {
  display: flex;
  align-items: center;
  position: relative;
  left: 0;
  font-size: 0;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transition: all 300ms ease;
  word-spacing: normal;
  width: 100%;
  max-height: 100%;
}

._GalleryItemSlideClass {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

@mixin makeControl($color: #000, $width: 4px) {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 15px;
  border-bottom: $width solid $color;
  transform-origin: center center;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    border-color: lighten($color, 20%);
  }
  &_next {
    border-right: $width solid $color;
    right: 0;
    transform: rotate(-45deg);
  }
  &_prev {
    border-left: $width solid $color;
    transform: rotate(45deg);
    left: 0;
  }
}

.gallery {
  @extend ._GalleryClass;
  max-width: 770px;
  margin: 0 auto;
  &-container {
    @extend ._GalleryContainerClass;
  }
  &-short-slides-list {
    position: relative;
    box-sizing: border-box;
    margin-left: -5px;
    margin-right: -5px;
    padding-top: 10px;
    &__item-slide {
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      width: 100 / 5 + 0%;
      max-height: 80px;
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 10px;
      cursor: pointer;
      img {
        width: 100%;
        max-width: 120px;
        height: 80px;
        object-fit: cover;
      }
      &-show-more {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, .6);
        color: #fff;
        text-align: center;
        font-size: 14px;
      }
    }
  }
  &-slide {
    @extend ._GalleryItemSlideClass;
    img {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    &__image {
      position: relative;
      width: 100%;
      height: auto;
    }
    &_out-side {
      padding-left: rem(50px);
      padding-right: rem(50px);
    }
  }
  &__control {
    @include makeControl();
  }
  &-count {
    &__container {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    &__item {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      max-width: 8px;
      max-height: 8px;
      margin-left: 4px;
      margin-right: 4px;
      background-color: #fff;
      border-radius: 100%;
      cursor: pointer;
    }
  }
  &-counter {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 20px;
    display: flex;
    justify-content: center;
    &__item {
      background-color: #fff;
      border-radius: 100%;
      width: 10px;
      height: 10px;
      margin-left: 5px;
      margin-right: 5px;
      cursor: pointer;
      @at-root {
        .gallery-counter__item.active {
          background-color: #ccc;
        }
      }
    }
  }
  &-control {
    &__button {
      $width: 4px;
      $color: #999999;
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      width: 30px;
      height: 56px;
      cursor: pointer;
      z-index: map-get($z-card, 2) + 1;
      &[data-control-button="previous"] {
        background-image: url("../../../../www/images/gallery/controls/arrow_left_simple_line_icons.svg");
        left: -50px;
      }
      &[data-control-button="next"] {
        transform: rotate(180deg);
        background-image: url("../../../../www/images/gallery/controls/arrow_left_simple_line_icons.svg");
        right: -50px;
      }
    }
  }
  &-wrapper-image {
    &_phone {
      width: 209px;
      background-image: url('../../../../www/images/gallery/phone/iPhone White Copy copy2.png');
      background-position: center;
      background-size: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: map-get($z-card, 2);
    }
  }
}

.gallery-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: map_get($z-card, super-modal);
  width: 100%;
  padding-top: 20px;
  display: none;
  flex-direction: column;
  justify-content: center;
  .gallery {
    padding-top: 40px;
  }
  &:before,
  &:after {
    display: block;
    content: "";
  }
  &-short-list {
    padding-top: 20px;
    position: relative;
    box-sizing: border-box;
    max-width: 938px;
    margin: 0 auto;
    width: 100%;
    z-index: 2;
    .gallery-short-slides-list__item-slide {
      width: 100 / 8 + 0%;
      img {
        width: 100%;
        max-width: 160px;
      }
    }
  }
  &__close-button {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: map_get($z-card, super-modal);
    cursor: pointer;
    &:before,
    &:after {
      content: "";
      display: block;
      position: relative;
      height: 28px;
      width: 2px;
      background-color: #fff;
    }
    &:before {
      transform: rotate(45deg);
      left: 1px;
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.car-details__sold {
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 50%;
    height: 50%;
    background-image: url("../../../../www/images/icons/sold/sold.png");
    background-position: 10% 10%;
    background-size: 60%;
    background-repeat: no-repeat;
    z-index: 1;
  }
}