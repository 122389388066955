@import "../settings/index";
.contact {
  &-layout {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  &-form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 320px;
    &__label {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      margin-bottom: 16px;
    }

    &__inpt {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      padding: 10px;
      border: none;
      background: #ededed;

      font-size: rem(14px);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      text-align: left;
      color: #303030;
      &:focus {
        outline: none;
      }

      &_large-text {
        resize: none;
        min-height: 90px;
      }
    }
    &__sbm-btn {
      cursor: pointer;
      max-width: 124px;
      height: 30px;
      border-radius: 15px;
      background: #582148;
      font-size: rem(14px);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      &:hover {
        background: lighten(#582148, 10%);
      }
    }
  }
  @media screen and (min-width: $desktop-view) {
    &-layout {
      justify-content: flex-start;
      flex-direction: row;
      font-size: rem(14px);
      margin-left: - rem(16px);
      margin-right: - rem(16px);
      &__item {
        padding-left: rem(16px);
        padding-right: rem(16px);
        
        &:first-child {
          width: 100%;
          max-width: 320px;
          margin-right: rem(48px);
        }

        p {
          font-size: rem(14px);
          letter-spacing: -0.2px;
          text-align: left;
          color: #222222;
          line-height: 1.2;
        }
      }
    }
    &-form {
      &__form-element {
        max-width: 210px;
        &[name="message"] {
          width: auto;
          max-width: none;
        }
      }
      .btn-full {
        margin: 0;
      }
    }
  }
  &-google-map__container {
    position: relative;
    padding: 20px 0;
  }
}