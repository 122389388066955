@import "../settings/index";
@import "../utils/media";

.accordion {
  $active-color: #f5f2f4;
  $text-color: #1e1e1e;
  $background-body-color: #fff;
  $z-index: map_get($z-card, normal);
  & {
    position: relative;
    box-sizing: border-box;
  }
  &-folder {
    position: relative;
    box-sizing: border-box;
    // border-bottom: 1px solid $active-color;
    &:first-child {
      border-top: 1px solid $active-color;
    }
    &.active {
      border-bottom: none;
      box-shadow: inset 0px -2px 2px 0px rgba(143,141,143,0.22);      
    }
    &__header {
      transition: all 100ms ease;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: rem(14px);
      text-align: left;
      width: 100%;
      position: relative;
      box-sizing: border-box;
      background-color: #fff;
      cursor: pointer;
      padding: rem($indent);
      margin: 0;
      z-index: $z-index;
      font-weight: 500;
      &:hover {
        background-color: darken(#fff, 2%);
        box-shadow: 0px 1px 2px 0px rgba(184,178,184,0.3);
        z-index: $z-index + 1;
      }
      &:after {
        position: absolute;
        right: $indent;
        top: rem($indent);
        display: block;
        content: "";
        height: rem(12px);
        width: rem(12px);
        border-right: 2px solid $text-color;
        border-bottom: 2px solid $text-color;
        transform: rotate(-45deg);
        transition: all 100ms ease;
      }
      &.active {
        border-color: transparent;
        box-shadow: 0px 1px 1px 1px rgba(184,178,184,0.2);
        &:after {
          transform: rotate(45deg);
        }
        &:hover {
          box-shadow: 0px 1px 2px 1px rgba(184,178,184,0.6);
        }
      }
    }
    &__body {
      position: relative;
      box-sizing: border-box;
      overflow: hidden;
      font-size: rem($normal-text);
      height: 0;
      z-index: 0;
      @at-root {
        .accordion-folder__header.active + .accordion-folder__body {
          height: auto;
        }
      }
      &.active {
        height: auto;
      }
    }
  }
  @at-root {
    .embedded {
      &-accordion {
        margin-left: - rem($indent);
        margin-right: - rem($indent);
        &-folder {
          &__header {
            font-weight: 400;
            color: lighten($text-color, 10%);
            border: none;
            background-color: darken($background-body-color, 1%);
            &::after {
              height: rem(8px);
              width: rem(8px);
              border-color: lighten($text-color, 20%);
            }
            &:hover, &.active, &:hover.active {
              box-shadow: none;
              z-index: $z-index;
            }
          }
          &__body {
            color: lighten($text-color, 12%);
            padding:0 rem($indent);
            background-color: darken($background-body-color, 4%);
          }
        }
      }
      @include media-desktop() {
        &-accordion {
          margin-left: 0;
          margin-right: 0;
          &-folder {
            &__header {
              background-color: inherit;
            }
          }
        }
      }
    }
  }
}
