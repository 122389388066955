@import "../utils/grid";
@import "../settings/index";
@import "../settings/typographic";

.search-result {

  &__title {
    font-size: rem($normal-text);
    font-weight: 400;
    color: $color-font_dark;
    margin-bottom: 14px;
  }
  &-grid-system {
    @include grid-container();

    &__left-side {
      @include grid-col($xs: 12, $md: 3, $lg: 3, $xl: 3);
    }

    &__right-side {
      @include grid-col($xs: 12, $md: 9, $lg: 9, $xl: 9);
    }
  }
}

