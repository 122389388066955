@import "../settings/index";
@import "../utils/index";

@mixin full-button($size, $color, $background, $border-color) {
  display: flex;
  box-sizing: border-box;
  list-style: none;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: rem($size);
  font-weight: 400;
  line-height: 2.2;
  padding-left: rem(8px);
  padding-right: rem(8px);
  border-radius: 2px;
  color: $color;
  border: 1px solid $border-color;
  background: $background;
  @content;
}

.filter {
  $space: 16px;
  
  & {
    margin-bottom: rem($space);
    padding-bottom: rem($space);
  }
  &-selectors-list {
    & {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-left: -$space;
      margin-right: -$space;
    }
    &__item-selector {
      display: flex;
      width: 50%;
      padding-left: $space;
      padding-right: $space;
      .search-listening__selector-link {
        @include full-button(rem(14px), #ffffff, #622651, #622651) {
          width: 100%;
          &:hover {
            background: lighten(#622651, 20%);
          }
        }
      }
      // &:last-child {
      //   .search-listening__selector-link {
      //     @include full-button(rem(14px), #2198ea, #ffffff, #2198ea) {
      //       width: 100%;
      //       &:hover {
      //         border-color: darken(#2198ea, 20%);
      //         color: darken(#2198ea, 20%);
      //       }
      //     }
      //   }
      // }
    }
    @include media-desktop() {
      &__item-selector {
        display: flex;
        width: 100 / 6 + 0%;
        white-space: nowrap;
        text-overflow: clip;
        word-break: keep-all;
        position: relative;
        .search-listening__selector-link {
          @include full-button(rem(14px), #444444, transparent, transparent) {
            &:hover {
              background: transparent;
              border-color: transparent;
              color: #000000;
            }
          }
        }
        // &:last-child {
        //   .search-listening__selector-link {
        //     @include full-button(rem(14px), #2198ea, #ffffff, #2198ea) {
        //       width: auto;
        //       border-radius: 5px;
        //       &:hover {
        //         border-color: darken(#2198ea, 20%);
        //         color: darken(#2198ea, 20%);
        //       }
        //     }
        //   }
        // }
      }
    }
  }
  @include media-desktop() {
    & {
      padding-bottom: 28px;
    }
    &__link {
      @include stylesheet-link($color: $color-font_dark);
    }
    &-selectors-list {
      display: block;
      column-count: 6;
      &__item-selector {
        color: $color-font_dark;
        background-color: transparent;
        width: auto;
        .search-listening__selector-link {
          font-weight: 500;
          color: $color-font_dark;
          font-size: 14px;
          text-overflow: clip;
          width: 100%;
          text-overflow: clip;
          overflow: auto;
        }
        &:hover {
          background-color: transparent;
          .search-listening__selector-link {
            color: lighten($color-font_dark, 10%);
          }
        }
      }
    }
  }
}

.filter-search {
  $link-color: #2585e3;
  &-header {
    text-align: right;
    margin-bottom: $indent;
  }
  &-block {
    margin-bottom: rem(30px);
  }
  &__clear {
    color: $link-color !important;
    text-decoration: underline !important;
    text-align: right;
    display: block;
    margin-bottom: 8px;
  }
  &__title {
    font-size: rem(16px);
    font-weight: 400;
    color: $color-font_dark;
    margin-bottom: rem(8px);
  }
}

.selectors-list {
  &__chisel {
    $select-color: #eaeaea;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 33px;
    background-color: $select-color;
    transition: all 300ms ease;
    font-size: rem(14px);
    padding-left: 8px;
    padding-right: 8px;
    &-text,
    &-link {
      display: block;
    }
  }
  &__select {
    display: block;
    line-height: 29px;
    margin-bottom: 8px;
    cursor: pointer;
    font-weight: 700;
    &-link {
      font-size: rem(14px);
      font-weight: 700;
      box-sizing: border-box;
      background-color: $color-primary;
      border: 2px solid $color-primary;
      transition: all 300ms ease-out;
      color: $color-font_light;
      padding-left: 8px;
      padding-right: 8px;
      display: block;

      &:hover {
        color: $color-primary;
        background-color: $color-font_light;
        //background-color: lighten($color-primary, 5%);
        //box-shadow: 0px 2px 2px 0px rgba(143, 141, 143, 1);
      }
      &:active {
        color: $color-primary;
        background-color: $color-font_light;
        //background-color: darken($color-primary, 5%);
        //box-shadow: 0px 2px 2px 0px rgba(143, 141, 143, 0.5);
      }
    }
    &.active {
      a {
        font-weight: 700;
        color: $color-primary;
        border: 2px solid $color-primary !important;
        background-color: #fff;
        cursor: default;
        &:hover {
          box-shadow: none;
        }
      }
    }
    // &:last-child {
    //   text-align: right;
    //   a {
    //     background-color: transparent;
    //     text-align: right;
    //     &:hover {
    //       box-shadow: none;
    //     }
    //   }
    // }
  }
}