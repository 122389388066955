@import "../settings/typographic";
@import "../settings/index";
.paginate {
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  &__list-counts {
    position: relative;
    box-sizing: border-box;
    margin: 0 rem(15px);
    padding: 0;
    &:before, &:after {
      display: inline-block;
      width: 17px;
      height: 17px;
      color: #eaeaea;
      text-align: center;
    }
    &:before {
      content: "<";
    }
    &:after {
      content: ">";
    }
    &-item {
      position: relative;
      box-sizing: border-box;
      display: inline-block;
      padding: rem(2px) rem(5px);
      &_active {
        background-color: #622651;
        color: #fff;
      }
    }
  }
  &__buttons-wrapper {
    width: 100%;
    // &:before, &:after {
    //   display: inline-block;
    //   color: #eaeaea;
    //   margin: 0 5px;
    // }
    // &:before {
    //   content: "<";
    // }
    // &:after {
    //   content: ">";
    // }
  }

  &__button {
    display: inline-block;
    font-size: rem(12px);
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-decoration: none;
    letter-spacing: -0.2px;
    text-align: left;
    color: #383838;
    // &:first-child {
    //   padding-right: 16px;
    // }
    // &:last-child {
    //   padding-left: 16px;
    // }
  }

  &__item {
    flex-grow: 0;
    font-size: rem(12px);
    font-weight: 500;
    letter-spacing: -0.2px;
    text-align: left;
    color: #383838;
  }


  &-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__pages-info {
    font-size: rem(14px);
    color: $color-font_dark;
    margin-right: 8px;
  }
  &__controller {
    margin-left: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      list-style: none;
    }
  }
  &__move {
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
  &__count {
    font-size: rem(12px);
    color: $color-font_dark;
    font-weight: 400;
    line-height: 20px;
    padding-left: 5px;
    padding-right: 5px;

    &.active {
      background-color: $color-primary;
      color: #fff;
    }
  }
}