@import "../settings/index";
.details-info {
  /**
   * @section Accordion
   * @begin
   */
  & {
    $active-color: #f5f2f4;
    $text-color: #1e1e1e;
    $z-index: map_get($z-card, normal);

    position: relative;
    box-sizing: border-box;
    margin-left: - rem($indent);
    margin-right: - rem($indent);
    margin-bottom: rem(60px);
    &-container {
      padding-top: $indent;
      margin-bottom: $indent;
    }
    &-title {
      transition: all 100ms ease;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: rem(14px);
      text-align: left;
      width: 100%;
      position: relative;
      box-sizing: border-box;
      background-color: #fff;
      cursor: pointer;
      padding: rem($indent);
      margin: 0;
      z-index: $z-index;
      &:hover {
        background-color: darken(#fff, 2%);
        box-shadow: 0px 1px 2px 0px rgba(184,178,184,0.3);
        z-index: $z-index + 1;
      }
      &:after {
        position: absolute;
        right: $indent;
        top: $indent;
        display: block;
        content: "";
        height: 12px;
        width: 12px;
        border-right: 2px solid $text-color;
        border-bottom: 2px solid $text-color;
        transform: rotate(-45deg);
        transition: all 100ms ease;
      }
      &_active {
        border-color: transparent;
        box-shadow: 0px 1px 1px 1px rgba(184,178,184,0.2);
        &:after {
          transform: rotate(45deg);
        }
        &:hover {
          box-shadow: 0px 1px 2px 1px rgba(184,178,184,0.6);
        }
      }
    }
    &__body {
      position: relative;
      box-sizing: border-box;
      height: 0;
      overflow: hidden;
      padding-left: rem($indent);
      padding-right: rem($indent);
      transition: all 300ms ease;
      &_open {
        height: auto;
        background-color: $active-color
      }
    }
  }
  /**
   * @end
   */

  &-list {
    position: relative;
    box-sizing: border-box;
    display: block;
    margin: 0;
    padding: 0;
    -moz-column-count: 2;
    -moz-column-gap: rem($indent);
    -webkit-column-count: 2;
    -webkit-column-gap: rem($indent);
    column-count: 2;
    column-gap: rem($indent);
    font-size: rem(14px);
    padding-top: rem($indent);
    margin-bottom: rem($indent);
    &__item {
      display: block;
      list-style: none;
      column-count: 2;
      word-break: break-all;
      margin-bottom: rem(8px);
      span {
        display: block;
      }
    }
  }
  /**
   * @section Tabs
   * @begin
   */
  &-tab {
    &-body {
      position: relative;
      box-sizing: border-box;
      background-color: #fff;
      margin-bottom: rem(30px);
      height: auto;
    }
    &-navigate {
      position: relative;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin-left: - rem($indent);
      margin-right: - rem($indent);
      &__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: rem(14px);
        text-align: left;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        background-color: #fff;
        cursor: pointer;
        padding: rem($indent);
        border-bottom: 1px solid #f0f0f0;
        &:first-child {
          border-top: 1px solid #f0f0f0;
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    @media screen and (min-width: $desktop-view) {
      &-navigate {
        $indent-tab: $indent / 2;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 2px solid $color-second;
        margin: 0;
        &__item {
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 100%;
          position: relative;
          box-sizing: border-box;
          margin-left: rem($indent-tab);
          margin-right: rem($indent-tab);
          background-color: #f0f0f0;
          cursor: pointer;
          padding: rem($indent / 2);
          border: none;
          transition: all 200ms ease;
          &:first-child {
            border-top: none;
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          &_active {
            background-color: $color-second;
            color: #fff;
          }
        }
      }
    }
  }
  /**
   * @end
   */
  @media screen and (min-width: $desktop-view) {
    & {
      margin-left: 0;
      margin-right: 0;
    }
  }
}