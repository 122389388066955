@import "../settings/index";
.photo-gallery {
  position: relative;
  box-sizing: border-box;
  display: block;
  margin-left: - rem($indent);
  margin-right: - rem($indent);
  &__main {
    position: relative;
    box-sizing: border-box;
  }
  &__active-photo {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  &-photos {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    margin-top: 0;
    padding: 0;
    width: 100%;

    flex-wrap: nowrap;
    justify-content: center;
    &__item-image {
      position: relative;
      box-sizing: border-box;
      list-style: none;
      display: block;
      padding-left: rem(5px);
      padding-right: rem(5px);
      margin-bottom: rem(10px);
      cursor: pointer;
      background-color: #c8c8c8;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin-left: rem(2px);
      margin-right: rem(2px);
      transition: all 100ms ease;
      &_active {
        background-color: #fff;
      }
    }
  }
  &__image {
    display: block;
    width: 100%;
    height: auto;
    transition: all 300ms ease;
  }
  @media screen and (min-width: $desktop-view) {
    & {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
    }
    &__main {
      position: relative;
      box-sizing: border-box;
    }
    &__active-photo {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      object-fit: cover;
      max-height: 300px;
    }
    &-photos {
      position: relative;
      box-sizing: border-box;
      flex-wrap: wrap;
      margin-top: 0;
      margin-bottom: rem(15px);
      padding: 0;
      margin-left: - rem(5px);
      margin-right: - rem(5px);
      padding-top: rem(10px);
      justify-content: flex-start;
      &__item-image {
        $item-width: 100 / 5 + 0%;
        object-fit: cover;
        box-sizing: border-box;
        padding: rem(5px);
        width: $item-width;
        height: 60px;
        margin-left: 0;
        margin-right: 0;
        cursor: pointer;
        background-color: inherit;
        border-radius: 0;
        &_active {
          transform: scale(1.1);
          box-shadow: 1px 1px 2px 0px rgba(179,177,179,0.4);
        }
      }
    }
  }
}
