@import "../settings/index";
@import "../utils/grid";
@import "../utils/media";
.list {
  &-grid {
    @include grid-container();
    &__row {
      @include grid-row();
      & {
        margin: 0;
      }
    }
    &__item {
      @include grid-col($xs: 6, $md: 4, $lg: 4, $xl: 4);
      & {
        margin-bottom: rem(20px);
      }
    }
  }
  &-horizontal {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    padding: 0;
    &__item {
      font-size: rem(16px);
      line-height: 2.38;
      letter-spacing: -0.3px;
      text-align: left;
      color: #585858;
      display: inline-block;
      list-style: none;
      margin-right: rem(8px);
      margin-left: rem(8px);
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-vertical {
    position: relative;
    display: block;
    box-sizing: border-box;
    line-height: 22px;
    padding-left: rem(25px);
    &__item {
      font-size: rem(16px);
      line-height: 2.38;
      letter-spacing: -0.3px;
      text-align: left;
      color: #585858;
      display: inline-block;
      list-style: none;
      margin-bottom: rem(8px);
      padding-left: rem(10px);
      &_second {
        list-style-image: url('../../../../www/images/icons/list/playlist_add_check_material_copy_9.svg');
      }
      &_primary {
        list-style-image: url('../../../../www/images/icons/list/playlist_add_check_material.svg');
      }
    }
  }

  @include media-desktop() {
    &-horizontal {
      & + & {
        margin-left: 20px;
      }
    }
  }
  &-numbered {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    &__item {
      font-size: rem(14px);
      font-weight: 600;
      list-style-position: inside;
      list-style: none;
    }
  }
}