$articles: (
        h1: 28px,
        h3: 20px,
        h4: 16px
);

$font-size: (
        xss: 12px,
        s: 14px,
        m: 16px,
        l: 18px,
        xl: 20px,
        xxl: 28px
);

$normal-text: map_get($font-size, m)!default;
$article-text: map_get($font-size, l)!default;


$font-stack: (
        'Open Sans',
        'Arial',
        sans-serif,
);
