@import "../settings/index";
.breadcrump {
  position: relative;
  box-sizing: border-box;
  margin-bottom: rem($indent);
  &-list {
    display: flex;
  }
  &__item {
    display: block;
    list-style: none;
    font-size: 0;
    & > div {
      display: inline-block;
    }
    &:after {
      display: inline-block;
      content: "-";
      margin: 0 rem($indent / 2);
      font-size: 16px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
