@import "../settings/typographic";
@import "../settings/index";
._Button-Base {
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  display: block;
  border: none;
  border-radius: rem(25px);
  text-align: center;
  font-size: map-get($font-size, xss);
  width: rem(125px);;
  line-height: rem(30px);
  margin: 0 auto;
  transition: all 200ms ease;
  &, &:focus, &:active {
    outline: none;
    border: none;
  }
}
.btn {
  &-full {
    @extend ._Button-Base;
    & {
      background-color: $color-primary;
      color: $color-font_light;
      &:hover {
        background-color: lighten($color-primary, 20%);
      }
    }
  }
  &-strict {
    &, &_color-second {
      @extend ._Button-Base;
      border-radius: 0;
    }
    &_color-second {
      max-width: none;
      background-color: $color-second;
      width: 100%;
      line-height: 52px;
      color: #fff;
      font-size: rem(14px);
      font-weight: 400;
      &:hover {
        background-color: lighten($color-second, 20%);
      }
    }
  }
}