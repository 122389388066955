@import "../../../../node_modules/sass-rem/rem";
@import "../../../../node_modules/normalize-scss/sass/normalize";
@import "../settings/typographic";
@import "../settings/index";
@import "../utils/media";

@mixin import-fonts() {
  @font-face {
    font-family: 'San Francisco';
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-MediumP4.eot');
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-MediumP4.eot?#iefix') format('embedded-opentype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-MediumP4.woff') format('woff'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-MediumP4.ttf') format('truetype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-MediumP4.svg#.HelveticaNeueDeskInterface-MediumP4') format('svg');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'San Francisco';
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-MediumItalicP4.eot');
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-MediumItalicP4.eot?#iefix') format('embedded-opentype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-MediumItalicP4.woff') format('woff'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-MediumItalicP4.ttf') format('truetype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-MediumItalicP4.svg#.HelveticaNeueDeskInterface-MediumItalicP4') format('svg');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'San Francisco';
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Italic.eot');
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Italic.eot?#iefix') format('embedded-opentype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Italic.woff') format('woff'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Italic.ttf') format('truetype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Italic.svg#.HelveticaNeueDeskInterface-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'San Francisco';
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Bold.eot');
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Bold.woff') format('woff'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Bold.ttf') format('truetype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Bold.svg#.HelveticaNeueDeskInterface-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'San Francisco';
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Thin.eot');
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Thin.eot?#iefix') format('embedded-opentype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Thin.woff') format('woff'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Thin.ttf') format('truetype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Thin.svg#.HelveticaNeueDeskInterface-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'San Francisco';
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-UltraLightP2.eot');
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-UltraLightP2.eot?#iefix') format('embedded-opentype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-UltraLightP2.woff') format('woff'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-UltraLightP2.ttf') format('truetype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-UltraLightP2.svg#.HelveticaNeueDeskInterface-UltraLightP2') format('svg');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'San Francisco';
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Regular.eot');
    src: url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Regular.woff') format('woff'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Regular.ttf') format('truetype'),
    url('../../../../www/fonts/San_Francisco/subset-HelveticaNeueDeskInterface-Regular.svg#.HelveticaNeueDeskInterface-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

}

@mixin initialize() {
  @include normalize();
  html {
    font-family: $font-stack;
    font-size: 14px;
    font-weight: 400;
    height: 100%;
  }

  @include media-desktop() {
    html {
      font-size: 16px;
    }
  }

  body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @each $tag, $value in $articles {
    #{$tag} {
      font-size: rem($value);
      margin: 0;
    }
  }

  div, figure, ul, li, ol, section, main, article, aside, footer, nav {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }


  a {
    padding: 0;
    margin: 0;
    color: inherit;
    &, &:hover, &:active, &:visited {
      text-decoration: none;
    }
    &:focus {
      outline: none;
    }
  }

  figure {
    margin: 0;
  }

  .article {
    font-size: rem(14px);
    font-weight: 600;
    line-height: 16px;
    margin-bottom: rem(8px);
    margin-top: 0;
  }

  .title {
    position: relative;
    text-align: left;
    font-size: rem(18px);
    font-weight: 500;
    margin-bottom: rem(16px);
    padding-top: rem(16px);
    margin-top: 0;
  }

  .text {
    font-size: rem(12px);
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.2px;
    text-align: left;
    color: #383838;
    &_right {
      text-align: right!important;
    }
  }

  .text_active-bold {
    color: $color-primary;
    font-weight: 600;
    font-size: rem(20px);
  }

  .container-center {
    position: relative;
    box-sizing: border-box;
    padding-left: rem(16px);
    padding-right: rem(16px);
    max-width: $container-width;
    margin: 0 auto;
  }

  .scroll-off {
    overflow: hidden;
  }

  .main {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    min-width: 320px;
    flex: 1 0 auto;
  }

  .link-text {
    color: #000;
    transition: all 200ms ease;
    &:active, &:visited {
      color: #000;
    }
    &:hover {
      color: lighten(#000, 50%);
    }
  }

  .hidden-desktop {
    display: block;
    @include media-desktop() {
      display: none;
    }
  }

  .hidden-mobile {
    display: none;
    @include media-desktop() {
      display: block;
    }
  }

  .grid-two-sides {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: - rem(16px);
    margin-right: - rem(16px);
    &__item-side {
      box-sizing: border-box;
      width: 100%;
      padding-left: rem(16px);
      padding-right: rem(16px);
    }
    @include media-desktop() {
      flex-wrap: nowrap;
      &__item-side {
        width: 50%;
      }
    }
  }

  .content_border {
    border-bottom: 4px solid $color-primary;
    margin-bottom: rem(16px);
  }

  .link-dark {
    color: $color-font_dark;
    &:hover {
      color: lighten($color-font_dark, 20%);
    }
  }

  .row_clear {
    margin-left: - rem(16px)!important;
    margin-right: - rem(16px)!important;
  }
  .item-block {
    text-align: right;
    margin-bottom: 20px;
  }

  .link-premary {
    font-size: rem(12px);
    color: #2585e3;
    text-decoration: underline;
    &:hover {
      color: darken(#2585e3, 20%);
    }
  }
  .link_full-parent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
  }

  p {
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }
}
