@import "../../../../node_modules/flexbox-grid-sass/src/flexbox-grid.scss";

@mixin grid-container() {
  @extend .grid;
  @extend .grid--container;
}

@mixin grid-col($xs: 12, $md: 12, $lg: 12, $xl: 12) {
  @extend .col;
  @extend .col--xs-#{$xs};
  @extend .col--md-#{$md};
  @extend .col--lg-#{$lg};
  @extend .col--xl-#{$xl};
  padding-left: 8px;
  padding-right: 8px;
  @content;
}

@mixin grid-row() {
  @extend .row;
}
